<template>
  <div class="bodys">
    <div class="content">
      <div v-html="detailArticleData.content"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "medicalDetail",
  data() {
    return {
      detailArticleData: "",
    };
  },
  mounted() {
    this.detailArticleData = JSON.parse(
      window.sessionStorage.getItem("currentArticle")
    );
  },
};
</script>

<style>
.content img{
  width: 99%;
}
.content{
  font-size: 0.4rem;
  width: 97%;
  margin: auto;
  margin-top: 0.2rem;
  text-align: left;
}
.bodys {
  width: 9rem;
  background-color: #fff;
  margin: 10px auto;
  padding: 10px;
  border-radius: 10px;
  -webkit-box-shadow: #e6e6e6 0px 0px 10px;
  -moz-box-shadow: #e6e6e6 0px 0px 10px;
  box-shadow: #e6e6e6 0px 0px 10px;
}
</style>